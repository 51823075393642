
export default {
	layout: 'game',

	async asyncData({ $axios }) {
		const knightSample = await $axios.get('/api/characters/home/top-knights/1').then(res => res.data[0])
		return { knightSample }
	},

	data() {
		return {
			mintedScrolls: 0,
			currentSlide: 0,
			toolSlider: null,
			teamSlider: null,
			toolSliderTexts: [
				{
					title: 'Reality Swap',
					description:
						'Trade securely with blockchain assurance, one of the most powerful tools #onPolygon<br/>Experience the convenience of effortlessly sending and receiving NFTs through Reality Swap',
				},
				{
					title: 'Reality Knight',
					description:
						'With the enchanting Scrolls, you can now embark on a transformation into a Knight of the Reality Kingdom.<br/> Embrace your own NFT and enjoy multiple benefits',
				},
				{
					title: 'Reality Raffle',
					description:
						'Raffle your NFTs with the lowest fees on the market. <br/>The more you raffle, the more your earn. ',
				},
			],

			roadmapItems: [
				{
					date: 'jun  2022',
					title: 'The launch of the collection',
					description:
						'In June 2022 we created the community on Twitter and Discord. A collection aimed at the medieval theme called “Reality Knights”, that later was renamed to Reality Kingdom.',
					delivered: true,
				},

				{
					date: 'may 2023',
					title: 'Silver Scrolls public mint',
					description:
						'We opened the doors to our kingdom so that everyone could be a part of this community. With the public mint, many were able to experience the platform and see how much we can build.',
					delivered: true,
				},

				{
					date: 'jun 2023',
					title: 'Reality Swap',
					description:
						'After the launch of our public mint, we implemented a tool that provides a great help to the community. The reality swap has zero fees to our holders, and has the best NFT trade experience ever.',
					delivered: true,
				},
				{
					date: 'jul 2023',
					title: 'Reality Raffle',
					description:
						'One of the first raffles on Polygon. The best structure and design that exists in the blockchain. The more you raffle, the more you win. Holders also benefit from lower fees on the platform.',
					delivered: true,
				},
				{
					date: 'nov 2023',
					title: 'Reality Cats',
					description:
						'Cats are animals that became companions for the Silver Knights. One of the best arts on Polygon, bring some benefits to who possess. All this, for free.',
					delivered: true,
				},
				{
					date: 'dec 2023',
					title: 'Reality Staking',
					description:
						'Stake your Knights, Cats and even future NFTs that we launch. Earn even more money with your NFTs and get special benefits within the ecosystem.',
					delivered: true,
				},
				{
					date: 'feb 2024',
					title: 'Reality Avatar',
					description:
						'Have you ever imagined yourself as a wizard? Maybe an 8-bit character? Even trying out a new hairstyle. With Reality Avatar, all of this is possible. A project intended for both web2 and web3.',
					delivered: true,
				},
			],

			feedbacks: [
				{
					image: '',
					title: 'Feedback',
					text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia an',
				},
				{
					image: '',
					title: 'Feedback',
					text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia an',
				},

				{
					image: '',
					title: 'Feedback',
					text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia an',
				},
			],

			partners: [
				{
					image: 'https://assets-global.website-files.com/5e73a1e3ba24f2cd5dd2232a/620b309e30add226c95d8b56_matic.png',
					name: 'Placeholder',
				},
				{
					image: 'https://assets-global.website-files.com/5e73a1e3ba24f2cd5dd2232a/620b309e30add226c95d8b56_matic.png',
					name: 'Placeholder',
				},
				{
					image: 'https://assets-global.website-files.com/5e73a1e3ba24f2cd5dd2232a/620b309e30add226c95d8b56_matic.png',
					name: 'Placeholder',
				},
				{
					image: 'https://assets-global.website-files.com/5e73a1e3ba24f2cd5dd2232a/620b309e30add226c95d8b56_matic.png',
					name: 'Placeholder',
				},
			],

			team: [
				{
					name: 'Stunner',
					image: '/img/team/stunner.png',
					role: 'CEO / CTO',
					description:
						'Software Engineer with 8 years of experience, worked on some billion dollar companies like: Salesforce, XP Inc, Rakuten. Currently trying to improve the blockchain space bulding the Reality Kingdom.',
					socials: {
						twitter: 'https://twitter.com/jns_stunner',
						linkedin: 'https://www.linkedin.com/in/victor-dias-spaceshipws/',
						github: 'https://github.com/XxStunner',
					},
				},
				{
					name: 'Capi',
					image: '/img/team/capi.png',
					role: 'CCO',
					description:
						"Businessman by choice and by obligation. I've been working in the digital market for over 11 years, where I started as a design intern, passing by each and every step to become the art director I am today. Besides that, I've had the pleasure of learning the art of negotiation from the best of the best, where I keep constantly learning. Nowadays I'm the head of CapiCorp, a design studio for streamers, with more than 450 clients in 10 countries, and these 4 years of existence are only the beginning.",
					socials: {
						twitter: 'https://twitter.com/soucapi',
						linkedin: 'https://www.linkedin.com/in/eduardoarcanjo',
					},
				},
				{
					name: 'Hakurei',
					image: '/img/team/hakurei.png',
					role: 'CMO',
					description:
						"Hey there! If you don't know me, nice to meet you! I'm currently working as CM on Reality Kingdom. Funny guy, with a looot of histories to tell. Feel free to ask me anything! I can assure you that you will be surprised. In web2 I'm the CEO of 3 companies that are in gastronomic and healthcare areas.",
					socials: {
						twitter: 'https://twitter.com/0xHakurei',
						linkedin: 'https://www.linkedin.com/in/felipe-allegretti/',
					},
				},
				{
					name: 'Night',
					image: '/img/team/night.png',
					role: 'Product Designer',
					description:
						'We turn dreams into reality, never forgetting to add a touch of creativity and a good dose of magic! 🎨✨',
					socials: {
						twitter: 'https://twitter.com/nightmomo1',
						linkedin: 'https://www.linkedin.com/in/vitor-c-01b881223/',
					},
				},
				{
					name: 'Stiler',
					image: '/img/team/stiler.png',
					role: 'Social Media Designer',
					description:
						"Hello, nice to meet you! My name is Matheus, Reality Kingdom's current graphic designer, responsible for the digital media demands and other design projects in general. I'm a guy who once dreamed of being part of a successful project and today I am fulfilling that dream by being a part of Reality Kingdom. If you have any questions about me, feel free to contact me, I'll be honored to answer you.",
					socials: {
						twitter: 'https://twitter.com/Stilergrr',
						linkedin: 'https://www.linkedin.com/in/matheusilvadsg/',
					},
				},
				{
					name: 'blumenbalt',
					image: '/img/team/blumenbalt.png',
					role: 'Developer',
					description:
						"Hey, been a developer ever since 2017, working on multiple projects using all sorts of technologies and been in environments, using my experience together with the team we've been developing blockchain applications for a while now and it's been a welcoming learning experience with lots of challenges, this is by far the project I'm most proud of and we're not even done yet.",
					socials: {
						twitter: 'https://twitter.com/blumenbalt',
						linkedin: 'https://www.linkedin.com/in/arthur-kenji-rosa-haguiuda-246684173/',
						github: 'https://github.com/blumenbalt',
					},
				},
				// {
				// 	name: 'Jaguar',
				// 	image: '/img/team/jaguar.png',
				// 	role: 'Developer',
				// 	description:
				// 		"Heyo. I'm a fullstack developer in ascension for Spaceship Web Solutions, and I've been working on this project ever since it was just a collection known as Reality Knights. I feel proud of the advancements we've made with the brand since then, and for the learning opportunities I've had in this project so far.",
				// 	socials: {
				// 		twitter: 'https://twitter.com/juagar_',
				// 		linkedin: 'https://www.linkedin.com/in/jaguar727/',
				// 		github: 'https://github.com/jaguar727',
				// 	},
				// },
				// {
				// 	name: 'Lilely',
				// 	image: '/img/team/lilely.png',
				// 	role: 'Developer',
				// 	description:
				// 		"Hi! I'm a fullstack developer, and currently I'm working for the Reality Kingdom project since some months ago. It's an honor to be part of this incredible project, where every day I can learn new things from this incredible team.",
				// 	socials: {
				// 		twitter: 'https://twitter.com/lilely13',
				// 		linkedin: 'https://www.linkedin.com/in/brunakobayachi/',
				// 		github: 'https://github.com/brunakobayachi',
				// 	},
				// },
				// {
				// 	name: 'Brx',
				// 	image: '/img/team/brx.png',
				// 	role: 'Developer',
				// 	description:
				// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
				// 	socials: {
				// 		twitter: 'https://twitter.com/BrenoDu09041150',
				// 	},
				// },
				// {
				// 	name: 'Bianca',
				// 	image: '/img/team/bianca.png',
				// 	role: 'Copywriter',
				// 	description:
				// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
				// 	socials: {
				// 		twitter: 'https://twitter.com/BrenoDu09041150',
				// 		linkedin: 'https://www.linkedin.com/in/devbiancamari/',
				// 	},
				// },
			],
			maxSupply: 0,
			totalMinted: 0,
		}
	},

	methods: {
		mountSliders() {
			if (!this.$refs.toolSlider) return
			if (typeof Glide === 'undefined') {
				return setTimeout(() => this.mountSliders(), 100) // 100ms
			}

			const carouselDefaultSettings = {
				perView: 3,
				focusAt: 'center',
				gap: 24,
				bound: true,
				breakpoints: {
					768: {
						perView: 1,
					},
				},
			}

			const autoplayInterval = 10000 // 10 seconds

			this.toolSlider = new Glide(this.$refs.toolSlider, { ...carouselDefaultSettings, startAt: 1 })

			this.teamSlider = new Glide(this.$refs.teamSlider, {
				...carouselDefaultSettings,
				autoplay: autoplayInterval,
				type: 'slider',
				focusAt: 0,
			})

			this.toolSlider.on(['move', 'move.after'], () => {
				this.currentSlide = this.toolSlider.index
			})

			this.toolSlider.mount({
				Coverflow: (Glide, Components, Events) => {
					const CoverflowPlugin = {
						tilt(slide) {
							Components.Html.slides[Glide.index].style.zIndex = 0
							slide.style.transform = 'perspective(2400px) rotateY(0deg)'
							this.tiltPrevElements()
							this.tiltNextElements()
						},
						tiltPrevElements() {
							const activeSlide = Components.Html.slides[Glide.index]

							const previousSlides = []
							const getPrevious = slide => {
								const previousSlide = slide.previousElementSibling
								if (previousSlide) {
									previousSlides.push(previousSlide)
									getPrevious(previousSlide)
								}
							}
							getPrevious(activeSlide)

							previousSlides.forEach((slide, index) => {
								slide.style.transformOrigin = '100% 50%'
								slide.style.transform = ` rotateY(${
									20 * Math.max(index, 2)
								}deg) translate3d(250px,0,-400px)`
								slide.style.zIndex = '-1'
								slide.style.transition = 'ease-out 400ms'
							})
						},
						tiltNextElements() {
							const activeSlide = Components.Html.slides[Glide.index]

							const nextSlides = []

							const getNext = slide => {
								const nextSlide = slide.nextElementSibling
								if (nextSlide) {
									nextSlides.push(nextSlide)
									getNext(nextSlide)
								}
							}
							getNext(activeSlide)

							nextSlides.forEach((slide, index) => {
								slide.style.transformOrigin = '0% 50%'
								slide.style.transform = ` rotateY(${
									-20 * Math.max(index, 2)
								}deg) translate3d(-250px,0,-400px)`
								slide.style.zIndex = '-1'
								slide.style.transition = 'ease-out 400ms'
							})
						},
						resetTilt() {
							const slides = Components.Html.slides
							for (let i = 0; i < slides.length; i++) {
								const slide = slides[i]
								slide.style.transform = ''
								slide.style.zIndex = ''
								slide.style.transformOrigin = ''
								slide.style.transition = ''
							}
						},
					}

					Events.on(['mount.after', 'run'], () => {
						Glide._o.perView == 1
							? CoverflowPlugin.resetTilt()
							: CoverflowPlugin.tilt(Components.Html.slides[Glide.index])
					})

					return CoverflowPlugin
				},
			})
			this.teamSlider.mount()
		},

		async fetchScrollData() {
			const { scrollContract, safestBlock } = await this.$getContracts()

			const [maxSupply, totalMinted] = await Promise.all([
				scrollContract.methods.maxSupply().call({}, safestBlock),
				scrollContract.methods.totalMinted().call({}, safestBlock),
			])

			this.maxSupply = maxSupply
			this.totalMinted = totalMinted
		},
	},

	computed: {
		loggedUser() {
			return this.$store.state.auth.user
		},
	},

	mounted() {
		this.mountSliders()
		this.fetchScrollData()
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll)
		if (this.glide) this.glide.destroy()
	},

	head() {
		return {
			title: `Reality Kingdom - Home`,
			meta: [
				{ hid: 'robots', name: 'robots', content: 'index, follow' },
				{
					name: 'description',
					content:
						'By uniting AI technology and the blockchain, Reality Kingdom strives towards redefining the way PFPs are viewed today, where you are your own NFT and part of an ecosystem that transcends the barriers of the internet itself, creatively solving real-world problems.',
				},
				{
					hid: 'og:title',
					property: 'og:title',
					content: `Reality Kingdom - Home`,
				},
				{
					hid: 'og:description',
					property: 'og:description',
					content:
						'By uniting AI technology and the blockchain, Reality Kingdom strives towards redefining the way PFPs are viewed today, where you are your own NFT and part of an ecosystem that transcends the barriers of the internet itself, creatively solving real-world problems.',
				},
			],
		}
	},
}
