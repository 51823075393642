
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		isExpandedByDefault: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isExpanded: false,
		}
	},
	mounted() {
		this.isExpanded = this.isExpandedByDefault
	},
}
